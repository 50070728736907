// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bS_k5";
export var caseStudyBackground__lineColor = "bS_kX";
export var caseStudyHead = "bS_k7";
export var caseStudyHead__imageWrapper = "bS_kV";
export var caseStudyProjectsSection = "bS_k6";
export var caseStudyQuote__bgLight = "bS_k3";
export var caseStudyQuote__bgRing = "bS_k2";
export var caseStudyVideoReview = "bS_lV";
export var caseStudyVideoReview__bgRing = "bS_lW";
export var caseStudyVideo__ring = "bS_k8";
export var caseStudy__bgDark = "bS_kT";
export var caseStudy__bgLight = "bS_kS";
export var caseStudy__bgLightReverse = "bS_lT";